<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import documentManagerGEN from "@/components/widgets/documentManagerGEN";
import calendarWidget from "@/components/widgets/calendarWidget";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Dettaglio mezzo",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    moment.locale('it')
    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showData:false,
        title: "Dettaglio mezzo",
        data:{},
        items: [
          {
            text: "Gestione mezzi",
            href: "/fleet/list-view",
          },
          {
            text: "Mezzo",
            active: true,
          },
        ],
        showMainDocuments:false,
        showMainCalendar:false,
    };
  },
  validations: {
      data: {
        name: {
          required: helpers.withMessage("Nome veicolo obbligatorio", required),
        },
        manufacturer: {
          required: helpers.withMessage("Marca veicolo obbligatoria", required),
        },
        plate: {
          required: helpers.withMessage("Targa veicolo obbligatoria", required),
        },
        ownershipDesc: {
          required: helpers.withMessage("Indicazione proprietà del veicolo obbligatoria", required),
        },
        kmStart: {
          required: helpers.withMessage("Indicazione KM iniziali obbligatoria", required),
        },
        kmEnd: {
          required: helpers.withMessage("Indicazione KM finali obbligatoria", required),
        },

      },

    },
  mounted() {
    this.getItem()
  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    submitForm(){
     
      document.getElementById("genDataForm").submit();
    },
    getItem(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}vehicles/${this.$route.params.id}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          if (response.data) {
            this.data = response.data;
           
          }
        })
    },
    getParent(){
      this.getItem()
    },
    updatedata(){
          this.submitted = true;
          this.v$.$touch()
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}vehicles/${this.data._id}`, this.data, {headers: { authorization:this.tk}} )
            .then((response)=>{
              if (response.data) {
                this.getFolder()
                Swal.fire({
                  title:"Dati aggiornati",
                  text:"Anagrafica mezzo aggiornata con successo!",
                  icon:"success",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:3000,
                }) 
              }
          })
    },
    getFolder(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}vehicles/folder/update/by-id/${this.$route.params.id}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          if (response.data) {
            alert(JSON.stringify(response.data))   
            this.getItem()
          }
        })
    },

  },
  components: {
    documentManagerGEN,
    calendarWidget,
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
              
              <template v-if="data">
                  <span class="mdi mdi-24px mdi-car-estate pr-5 "></span>
                  Scheda Mezzo: <span class="bold text-primary">{{ data.name }}</span>
              </template>
              </h4>

            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showData"
                />
              </div>
            </div>
          </div>
         <template v-if="data">
          <div class="card-body" v-if="showData">
            <form class="needs-validation" @submit.prevent="updatedata">
              <div class="row mb-3">
                <div class="col-lg-4 col-md-12">
                  <div class="mb-3">                
                    <label for="name" class="form-label">Denominazione mezzo <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="data.name" id="name" :class="{
                                      'is-invalid': submitted && v$.data.name.$error,
                                    }" placeholder="Inserisci denominazione mezzo" required>
                      <div v-for="(item, index) in v$.data.name.$errors" :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                  </div>       
                </div>
                <div class="col-lg-3 col-md-12">
                  <div class="mb-3">                
                    <label for="manufacturer" class="form-label">Marca mezzo<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="data.manufacturer" id="manufacturer" :class="{
                                      'is-invalid': submitted && v$.data.manufacturer.$error,
                                    }" placeholder="Inserisci marca del mezzo" required>
                      <div v-for="(item, index) in v$.data.manufacturer.$errors" :key="index" class="invalid-feedback">
                                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>      
                </div>
                <div class="col-lg-2 col-md-12">
                  <div class="mb-3">                
                    <label for="plate" class="form-label">Targa<span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="data.plate" id="plate" :class="{
                                      'is-invalid': submitted && v$.data.plate.$error,
                                    }" placeholder="Inserisci Targa del mezzo" required>
                      <div v-for="(item, index) in v$.data.plate.$errors" :key="index" class="invalid-feedback">
                                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>      
                </div>
                <div class="col-md-12 col-lg-3" >
                  <div class="mb-3">
                    <label for="nomeFascicolo" class="form-label">Nome Fascicolo (Cartella)</label>
                    <div class="form-control-green1"><strong>{{ data.nomeFascicolo }}</strong></div>
                  </div>  
                </div>
              </div>
              <div class="row mb-3">

                <div class="col-lg-8">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-8">
                  <div class="mb-3">
                    <label for="details" class="form-label">Descrizione (facoltativa)</label>
                      <textarea class="form-control " id="details" placeholder="Descrizione" v-model="data.details"></textarea>
                        <div class="invalid-feedback">        
                        </div>
                    </div>    
                </div>
                <div class="col-lg-4">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="ownershipDesc" class="form-label">Proprietà del mezzo<span class="text-danger">*</span></label>

                    <select class="form-select" required aria-label="Proprietà del mezzo" v-model="data.ownershipDesc" :class="{
                                      'is-invalid': submitted && v$.item.ownershipDesc.$error,
                                    }" >
                        <option value="">Seleziona Proprietà</option>
                        <option value="aziendale">Aziendale</option>
                        <option value="locazione">In locazione</option>
                        <option value="noleggio">A noleggio</option>
                    </select>
                    <div v-for="(item, index) in v$.data.ownershipDesc.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <label for="manufacturer" class="form-label">Km Iniziali<span class="text-danger">*</span></label>
                    <input type="number" required class="form-control" v-model="data.kmStart" id="manufacturer" :class="{'is-invalid': submitted && v$.data.kmStart.$error,}" placeholder="Inserisci indirizzo email">
                    <div v-for="(item, index) in v$.data.kmStart.$errors" :key="index" class="invalid-feedback">
                      <span v-if="item.$message">{{ item.$message }}</span>
                  </div> 
                </div>
                <div class="col-lg-2">
                  <label for="manufacturer" class="form-label">Km Attuali<span class="text-danger">*</span></label>
                    <input type="number" required class="form-control" v-model="data.kmEnd" id="manufacturer" :class="{'is-invalid': submitted && v$.data.kmEnd.$error,}" placeholder="Inserisci KM finali">
                  <div v-for="(item, index) in v$.data.kmEnd.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-lg-12 text-center">
                  <button type="submit" class="btn btn-outline-secondary custom-toggle" size="lg">
                    <span class="icon-on"><i class="ri-save-line align-bottom me-1"></i> Aggiorna</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
         </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <template v-if="data">
                          <template v-if="data.nomeFascicolo && data.nomeFascicolo!=''">
                            <span class="mdi mdi-24px mdi-file-document pr-5 text-success"></span>
                            <span class="pr-5">Documentazione </span> 
                          </template>
                          <template v-else>
                            <span class="mdi mdi-24px mdi-file-document pr-5 text-danger"></span>
                            <span class="pr-5 text-danger bold">Documentazione assente: nome fascicolo non esistente; completa anagrafica mezzo.</span> 
                          </template>
                        </template>
                        <template v-else>
                          <span class="mdi mdi-24px mdi-file-document pr-5 text-danger"></span>
                          <span class="pr-5 text-danger bold">Documentazione assente: nome fascicolo non esistente; completa anagrafica mezzo.</span> 
                        </template>
                        
                        
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
              <template v-if="data">
                <template v-if="data.nomeFascicolo && data.nomeFascicolo!=''">
                  <label for="FormSelectSystem" class="form-label text-muted"
                    >Mostra</label
                  >
                  <input
                    class="form-check-input code-switcher"
                    type="checkbox"
                    id="FormSelectSystem"
                    v-model="showMainDocuments"
                  />                          
                </template>
              </template>
              </div>
            </div>
          </div>
         <template v-if="data">
          <div class="card-body" v-if="showMainDocuments">
            <template v-if="data">
              <documentManagerGEN 
                :url="`${this.UrlServer}storage/by/project`"
                :projectID="data._id" 
                :data="data" 
                :bucket="data.nomeFascicolo" 
                type="directory_principale"
                refDirectory="/"
                fileType="multiple" 
                fileAccept="*" 
                mod="fleet"
                title="Documentazione mezzi"
                v-on:getParent="getParent()"/>
            </template>                
          </div>
         </template>
        </div>
      </div>
    </div>
    <div class="row" v-if="data">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
           
            <div class="card-title mb-0 flex-grow-1">

                <div class="row">
                  <div class="col-lg-3">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        
                        <span class="mdi mdi-24px mdi-calendar pr-5 "></span>
                        <span class="pr-5">Calendario utilizzo e scadenze 

                       
                        </span> 
                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
              &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showMainCalendar"
                />
              </div>
            </div>
          </div>
         <template v-if="data">
         
          <div class="card-body" v-if="showMainCalendar">
            <template v-if="data">
              <!-- PREVEDERE COME PARAMETRO DI calendarWidget "type"=> "user", "area", "vehicle", etc. -->
              
              <calendarWidget type="vehicle"  :resourceID="data._id" module="fleet" />
            </template>                
          </div>
         </template>
        </div>
      </div>
    </div>
  </Layout>
</template>
