export const dirListVehicleA = [

    {
        id: 1,
        name: 'Assicurazione',
        value: 'assicurazione',
        description: 'Assicurazione',
        tag:'vehicle_doc_type',
    },
    {
        id: 2,
        name: 'Bollo',
        value: 'bollo',
        description: 'Bollo',
        tag:'vehicle_doc_type',
    },
    {
        id: 3,
        name: 'Generale',
        value: 'generale',
        description: 'Generale',
        tag:'vehicle_doc_type',
    },
    {
        id:4,
        name: 'Sinistri',
        value: 'sinistri',
        description: 'Sinistri',
        tag:'vehicle_doc_type',
    },

]
export const dirListCustomerA = [
    {
        id: 1,
        name: 'Atti di proprietà',
        value: 'atti_di_proprieta',
        description: 'Atti di proprietà',
        tag:'customer_doc_type',
    },
    {
        id:2,
        name: 'Contratti',
        value: 'contratti',
        description: 'Contratti',
        tag:'customer_doc_type',
    },
    {
        id:3,
        name: 'Documenti di identità',
        value: 'documenti_identita',
        description: 'Documenti di identità',
        tag:'customer_doc_type',
    },
    {
        id: 3,
        name: 'Pagamenti',
        value: 'pagamenti',
        description: 'Pagamenti',
        tag:'customer_doc_type',
    },
    {
        id: 4,
        name: 'Varie',
        value: 'varie',
        description: 'Varie',
        tag:'customer_doc_type',
    },

]
export const dirListUserA = [

    {
        id: 1,
        name: 'Assicurazione',
        value: 'assicurazione',
        description: 'Assicurazione',
        tag:'user_doc_type',
    },
    {
        id: 2,
        name: 'Bollo',
        value: 'bollo',
        description: 'Bollo',
        tag:'user_doc_type',
    },
    {
        id: 3,
        name: 'Generale',
        value: 'generale',
        description: 'Generale',
        tag:'user_doc_type',
    },
    {
        id:4,
        name: 'Sinistri',
        value: 'sinistri',
        description: 'Sinistri',
        tag:'user_doc_type',
    },

]