<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
//import axios from 'axios';
import serverConfig   from '@/helpers/config';
//import DropZone from "@/components/widgets/dropZone";
import Swal from "sweetalert2";
import axios from 'axios';
//import { ref } from "vue";
//import { bucket } from '@/firebase'
import detailFile from "@/components/widgets/detailFile";
//import { relativeTimeRounding } from 'moment';
import Camera from "simple-vue-camera";
import gallery from "@/components/gallery";
//import { ImgTitle } from 'vue-easy-lightbox/src/components/img-title.js';
import {  dirListCustomerA, dirListUserA, dirListVehicleA  } from "@/components/widgets/utilsSettings";

export default {
  name: "documentManagerGEN",
  emits:['getParentProject','getParent'],
  props: [
    'projectID',
    'productID',
    'productCode',
    'productEan',
    'url',
    'customerID',
    'data',
    'bucket',
    'type',
    'fileType',
    'fileAccept',
    'title',
    'refDirectory',
    'getTag',
    'mod',
  ],
  setup(props) {
    const propsSetup = props
    const store = useStore()

    let base64 = false
    let tk = 'Bearer '+localStorage.getItem('tk')     
    const camera = ref(Camera);
    let urlRespServer = ''
    let propData

    const snapshot = async () => {
            //let baseData = false
            const blob = await camera.value?.snapshot(
            { width: 1920, height: 1080 },
            "image/png",
            0.5);
            // To show the screenshot with an image tag, create a url
            const url = URL.createObjectURL(blob);
            var reader = new FileReader();
            //baseData = reader.readAsDataURL(blob);
            reader.readAsDataURL(blob);

           reader.onloadend = async function () {
               var base64data = reader.result;

               var body = {
                url:url,
                buffer:base64data,
                dataFields:{
                  bucket: propsSetup.bucket,
                  projectID: propsSetup.projectID,
                  customerID: propsSetup.data.customerID,
                  customerName:'', 
                  authorTags: store.state.auth.currentUser.firstName +' '+store.state.auth.currentUser.lastName+' '+store.state.auth.currentUser.email, 
                  author: store.state.auth.currentUser,
                  subdir_tag: propsSetup.refDirectory,
                }
               }
            let postSnapshot = await axios.post(`${serverConfig.EP}storage/snapshot`, body,
             {
              headers: { authorization: 'Bearer '+localStorage.getItem('tk')},
              onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  
                  /*
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                  */
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Dismiss...')
                }
              });

            },
            
            })

            if (postSnapshot){
              if(postSnapshot.data){
                  urlRespServer = 'immagine acquisita'
                  Swal.fire({
                  title:"Immagine acquisita con successo",
                  text:"L'immagine è stata acquisita dal sistema",
                  icon:"success",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                }).then(()=>{
                  location.reload()
                })
              }
            }

          }
               
    }
    
    return { 
      tk, 
      propsSetup,
      camera,
      snapshot,
      base64,
      gallery,
      urlRespServer,
      propData,
    };
  },
  data() {
	return {
          UrlServer: serverConfig.EP,
          datastore: this.$store.state,
          page: 1,
          perPage: 10,
          sortRes:'desc',
          value: 'All',
          pages: [],
          paginated:{},
          select:'all',
          search:'',
          fileList:[],
          pathList:[],
          selectID:'',
          fileStorage:[],
          uploadValue:0,
         
          fileData:[],
          file:'',
          n_results:0,
          qSearch:'',
          resultsList:[],
          showImportFileMod:false,
          cameraDiv:false,
          subdir_tag:'/', // default: '/' => directory principale
          subdir_bucket:'',
          subDir_1:'',
          subDir_2:'',
          subDir_3:'',
          imgInList:[],
          galleryComponent:false,
          subDirAListVehicles : dirListVehicleA,
          subDirListCustomerA : dirListCustomerA,
          subDirListUserA: dirListUserA,
          docTypeOptions : [],
      }
	},
	computed:{ 
		
	},
  mounted(){
    this.subdir_bucket = this.bucket
    if (this.refDirectory!='/'){
        this.getBaseDir()
    } else {
        this.getList(this.projectID)
    }
    this.getDocTypeOptions()
  },
	methods:{
    len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
    roundTo(value, decimalpositions)
    {
      var i = value * Math.pow(10,decimalpositions);
      i = Math.round(i);
      return i / Math.pow(10,decimalpositions);
    },
    axiosInterceptor(){
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
              Swal.fire({
                title:"Accesso non autorizzato",
                text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }
                return Promise.reject('Unauthorized access');
            } else if (error.response && error.response.status  === 404) {
                Swal.fire({
                  title:"Nessun risultato trovato",
                  text:"Mi spiace, nessun risultato trovato",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            }  else if (error.response && error.response.status  === 412) {
              Swal.fire({
                title:"Credenziali mancanti",
                text:"Inserisci delle credenziali valide, per favore.",
                icon:"warning",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              return Promise.reject('The server cannot find the requested resource');
          } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                  title:"Risorsa non accessibile",
                  text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                this.$router.push('/login')
                if(localStorage.getItem('tk')){
                  localStorage.removeItem('tk')
                }                
                return Promise.reject('The server cannot find the requested resource');
            }
            else if (error.response && error.response.status  === 500) {
              Swal.fire({
                title:"Errore Server",
                text:"Mi spiace, ma riscontriamo degli errori sul server.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Internal Server Error');
            }
            else if (error.response && error.response.status  === 502) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('Bad Gateway');
            }
            else if (error.response && error.response.status  === 503) {
              Swal.fire({
                title:"Servizio non disponibile",
                text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
                return Promise.reject('Service Unavailable');
            } else {
              console.log(error.response.status )
              Swal.fire({
                title:"Errore imprevisto",
                text:"Ops! si è verificato un problema...riprova, per favore.",
                icon:"error",
                showCancelButton:false,
                showConfirmButton:false,
                timer:2000,
              })
              
            }

        }
      );
      
    },
    previewFile(event) {
      this.uploadValue=0;
      this.fileList=null;
      this.fileData = event.target.files;
      this.onUpload()
    },
    previewFileImport(event) {
      this.uploadValue=0;
      this.fileList=null;
      this.fileData = event.target.files;
      this.onUploadToServer()
    },
    async onUpload(){
      const formData = new FormData()
      
      formData.append('bucket', this.subdir_bucket)
      formData.append('projectID', this.projectID)
      if (this.data.customerDetails) {
        if (this.data.customerDetails._id) {
          formData.append('customerID', this.data.customerDetails._id)
        }
        if (this.data.customerDetails.ragSoc) {
          let name = `${this.data.customerDetails.ragSoc} `
          if (this.data.customerDetails.nome) {
            name += ` ${this.data.customerDetails.nome} `
          }
          if (this.data.customerDetails.cognome) {
            name += ` ${this.data.customerDetails.cognome} `
          }
          formData.append('customerName', name)
        }
      }
      
      if (this.datastore) {
        if (this.datastore.auth.currentUser) {
          let user =this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          if (this.datastore.auth.currentUser.email) {
            user += ` ${this.datastore.auth.currentUser.email} `
          }
          formData.append('authorTags', user)
          let author = {}
          author.uid = this.datastore.auth.currentUser.uid
          author.name = this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          author.email = this.datastore.auth.currentUser.email

          formData.append('author', author)
        }
      }
      

      formData.append('subdir_tag', this.subdir_tag)

      for (const i of Object.keys(this.fileData)) {
        formData.append('files', this.fileData[i])
      }

      this.axiosInterceptor()
      axios.post(this.UrlServer+'storage/multi-upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) =>
            {
              const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
              let timerInterval;
              Swal.fire({
                title: "Caricamento...",
                timer: percentUploaded,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                  const timer = Swal.getPopup().querySelector("b");
                  timerInterval = setInterval(() => {
                    timer.textContent = `${Swal.getTimerLeft()}`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                }
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  console.log('Caricamento...')
                }
              });

            },
      })
      .then((res) => {
        if(res){
          if (res.status==200) {
            this.getList(this.projectID)
          }
        }
      })
    },
    async onUploadToServer(){
      const formData = new FormData()
      
      formData.append('bucket', 'public/data')
      formData.append('projectID', this.projectID)
      formData.append('productID', this.productID)
      if (this.datastore) {
        if (this.datastore.auth.currentUser) {
          let user =this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          if (this.datastore.auth.currentUser.email) {
            user += ` ${this.datastore.auth.currentUser.email} `
          }
          formData.append('authorTags', user)
          let author = {}
          author.uid = this.datastore.auth.currentUser.uid
          author.name = this.datastore.auth.currentUser.firstName +' '+this.datastore.auth.currentUser.lastName
          author.email = this.datastore.auth.currentUser.email
          author.id = this.datastore.auth.currentUser.id
          formData.append('author', author)
          formData.append('createdBy', author)
        }
      }

      for (const i of Object.keys(this.fileData)) {
        formData.append('files', this.fileData[i])
      }
      console.log(formData.files)
      this.axiosInterceptor()
      axios.post(this.UrlServer+'api/upload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        
      })
      .then(() => {
        alert('caricati')
        
      })
    },

    replName(val,el){
      if (val.includes(el)){
        return val.replaceAll(el,' ')
      } else {
        return val
      }
    },
    formatKb(val){
      if (val){
        return this.roundTo(val / 1024, 1)
      } else {
        return ''
      }
    },
    filterList(id){
      let file = this.pathList.filter(function(el){
        return el._id == id
      })
      if (file){
        return file[0].url
      } else {
        return ''
      }
   
    },
    fileTypeCheck(file){
      if (file){
        if (file.split('/')[0] == 'image'){
          return 'image'
        } else {
          return file
        }
      
      }
    },
    getBaseDir(){
        this.subdir_tag = this.refDirectory
        this.subDir_1 = this.refDirectory
        if (this.subDir_1.charAt(0)=='/'){
            this.subDir_1.slice(1)
        }
        if (this.bucket.charAt(0)=='/'){
            this.bucket.slice(1)
        }
        if (this.bucket.charAt(this.bucket.length - 1) =='/'){
            this.bucket.slice(0, -1)
        }
        this.subdir_bucket = `${this.bucket}${this.refDirectory}`
        this.getList(this.projectID)
    },
    subDirA(){
        if(this.subDir_1!=''){
            this.subdir_tag = this.subDir_1
            if (this.subDir_1.charAt(0)=='/'){
                this.subDir_1.slice(1)
            }
            if (this.bucket.charAt(0)=='/'){
                this.bucket.slice(1)
            }
            if (this.bucket.charAt(this.bucket.length - 1) =='/'){
                this.bucket.slice(0, -1)
            }
            this.subdir_bucket = `${this.bucket}${this.subDir_1}`
        } else {
            this.subdir_tag = '/'
            this.subDir_2 = ''
        }  
        this.getList(this.projectID)
    },
    subDirB(){
        if(this.subDir_2!='' && this.subDir_1=='documentazione_tecnica'){
            this.subdir_tag = this.subDir_2
            this.subdir_bucket = `${this.bucket}${this.subDir_1}/${this.subDir_2}/`
        } else {
            this.subdir_tag = '/'
            this.subDir_2 = ''
        }
        this.getList(this.projectID)
    },
    subDirC(){
        if(this.subDir_3!='' && this.subDir_1=='documentazione_tecnica'){
            this.subdir_tag = this.subDir_3
            this.subdir_bucket = `${this.bucket}${this.subDir_1}/${this.subDir_2}/${this.subDir_3}/`
        } else {
            this.subdir_tag = '/'
            this.subDir_3 = ''
        }
        this.getList(this.projectID)
    },
    /*
    getParent(){
      alert('get 1')
      this.getList(this.projectID)
      this.getParentProject()
      alert('get')
    },
    */
    getParentProject(){
      this.getList(this.projectID)
      this.$emit('getParent')
      window.location.reload()
    },
    getList(value){

      this.axiosInterceptor()
      axios.get(this.url, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
            selectId: value,
            subdir_tag:this.subdir_tag,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        this.fileList  = response.data.results.data
        this.imgInList = response.data.n_images
        this.n_results = response.data.n_results
        this.paginated = response.data.results
        //this.$emit('getParent')
      })
    },
    getPathList(data) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/db/by/file-path-list`, 
        { 
          params: { 
            fileList: data,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
          this.fileList  = data
          this.pathList  = response.data
        })
    },
    getDocTypeOptions() {
      let tag
      if (this.mod =='fleet') {
        tag = "vehicle_doc_type"
      }
      if (this.mod =='customers') {
        tag = "customer_doc_type"
      }
      if (this.mod =='users') {
        tag = "user_doc_type"
      }
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}settings/all/documents/tag/${tag}`, 
        { 
          headers: { 'authorization':this.tk}
        }).then(response=>{
          if (response.data) {
            this.docTypeOptions  = response.data
          }
        })
    },
     getFilePath(filePath,index) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/f/db/to/gcloud/get-signed-url`, 
        { 
          params: { 
            filepath: filePath,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{

          if (response.data){
            this.fileList[index].resp = response.data
          } else {
            this.fileList[index].resp = false
          }
        
        })
    },
    deletedata(id,data){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              self.axiosInterceptor()
              axios.get(`${self.UrlServer}storage/f/db/to/gcloud/delete`, 
                { 
                  params: {
                    fileId: id,
                    filepath: data,
                  }, 
                  headers: { 'authorization':self.tk}
                }
              ).then(response=>{
                 if (response.status==200) {
                      Swal.fire({  
                      title: "File rimosso" , 
                      text: "Il file è stato rimosso con successo!", 
                      icon:"success",              
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000
                    })
                    self.getList(self.projectID)
                 }

              })
            }
        });


    },
    async getFileUrl(path){
      this.axiosInterceptor()
      let url = await axios.get(`${this.UrlServer}storage/download`, 
        { 
          params: { 
            filepath: path,
           
          }, 
          headers: { 'authorization':this.tk}
        })
        
        console.log(url.data)
        return url.data 
    },
    paginateAct(pg){
      this.page = pg
      this.getList(this.projectID)
    }, 
    getChild()  {
      this.galleryComponent=true
      this.$refs.passInChild.getGalleryList(this.subdir_tag);
  
    }
	},
  components: {
    //DropZone,
    detailFile,
    gallery,

  },
};
</script>
<template>
  <div class="back-grey">

    <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">{{title}}</h5>
          </div>
          <div class="card-body">
            <div class="row mb-20">
              <div class="col-md-3 col-lg-12 text-left mb-2 mt-2">
              <h5>Directory principale:</h5>
             
              <b-alert show  variant="secondary" class="alert-top-border fade show" role="alert">
                <i class="bx bx-folder me-3 align-middle fs-16 text-secondary"></i>
                <strong>
                    {{ bucket }}
                </strong>
              </b-alert>
              </div>
            </div>

            <div class="row mb-20">
              <div class="col-md-12 col-lg-3 text-left mb-2 mt-2">
             
                <div class="input-group">
                    <label class="input-group-text" for="subDir_1">
                      <i class="bx bx-folder me-3 align-middle fs-16 text-secondary"></i>
                    </label>
                  
                    <template v-if="type=='directory_principale'">
                        <select class="form-select" id="subDir_1" v-model="subDir_1" @change="subDirA()">
                            <option value="">Seleziona sottodirectory</option>
                            <option  v-for="item in docTypeOptions" :key="item.id" :value="item.value">{{ item.name }}</option>                       
                        </select>
                    </template>
                    <template v-else>
                        <select class="form-select" id="subDir_1" v-model="subDir_1"  disabled>
                            <option value="">Seleziona sottodirectory</option>
                            <option  v-for="item in docTypeOptions" :key="item.id" :value="item.value">{{ item.name }}</option>                       
                        </select>
                    </template>
                </div>
              </div>
                <div class="col-md-12 col-lg-4 text-left mb-2 mt-2">
                    <div class="input-group">
                        <template v-if="subDir_1=='documentazione_tecnica'">
                            <label class="input-group-text" for="subDir_2">
                                <i class="bx bx-folder me-3 align-middle fs-16 text-secondary"></i>
                            </label>
                            <select class="form-select" id="subDir_2" v-model="subDir_2"  @change="subDirB">
                                <option value="">Seleziona Documentazione Tecnica</option>
                                <option value="progettazione">Progettazione</option>
                                <option value="enel">Enel</option>
                                <option value="gse">GSE</option>
                                <option value="enea">Enea</option>
                                <option value="contabilita">Contabilità</option>
                                <option value="termus">Termus</option>
                            </select>
                        </template>
                        <template v-if="subDir_1=='bando'">
                            <label class="input-group-text" for="subDir_2">
                                <i class="bx bx-folder me-3 align-middle fs-16 text-secondary"></i>
                            </label>
                            <select class="form-select" id="subDir_2" v-model="subDir_2" >
                                <option value="">Seleziona Tipologia Bando</option>
                                <option value="cat">Da Sardegna CAT</option>
                                <option value="invito">Invitati</option>
                            </select>
                        </template>
                    </div>
                </div>
              <div class="col-md-12 col-lg-3 text-left mb-2 mt-2">
                <div class="input-group">
                    <template v-if="subDir_2=='enel' && subDir_1=='documentazione_tecnica'">
                        <label class="input-group-text" for="subDir_3">
                            <i class="bx bx-folder me-3 align-middle fs-16 text-secondary"></i>
                        </label>
                        <select class="form-select" id="subDir_3" v-model="subDir_3"  @change="subDirC" >
                            <option value="">Seleziona ENEL</option>
                            <option value="domanda">Domanda</option>
                            <option value="altre">Altre</option>
                            <option value="fine_lavori">Fine lavori</option>
                        </select>
                    </template>
                </div>                
              </div>
            </div>
            <div class="row ">
              <div class="col-md-3 col-lg-12 text-left mb-2 mt-30">
                <template v-if="cameraDiv==false">
                  <button class="btn btn-info" type="button" @click="cameraDiv=true"><span class="bx bx-plus"></span><i class="bx bx-camera"></i> Attiva fotocamera</button>
                </template>
                <template v-else>
                  <button class="btn btn-light" type="button" @click="cameraDiv=false"><span class="bx bx-x"></span><i class="bx bx-camera"></i> Chiudi fotocamera</button>
                </template>
                
              </div>
              <div class="col-md-9 col-lg-12 text-left mb-2 mt-2">{{urlRespServer}}</div>
            </div>
            
            <div class="row mb-2" v-if="cameraDiv==true">
              <div class="col-12 mb-2 mt-2 text-center">
                <camera :resolution="{ width: 375, height: 212 }" ref="camera" autoplay></camera>
                <button class="btn btn-primary " @click="snapshot"><i class="bx bx-camera"></i> Scatta foto</button>
              </div>
            </div>
            <div class="row mb-10 mt-10">
              <div class="col-12 mb-2 mt-2 text-center">
               
              </div>
            </div>
            <div class="row mt-10">
              <div class="" v-if="showImportFileMod==false">
                <p class="text-muted">Aggiungi allegati</p>
                <div class="dropzone position-relative">
                  <div class="mb-1">
                    <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
                  </div><h5>Carica file</h5>
                  <label for="dropzoneFile" class="bg-light text-dark stretched-link">Clicca sopra</label>
                  <input class="dropzoneFile btn btn-primary" id="dropzoneFile" type="file" :accept="fileAccept"  multiple="multiple" @change="previewFile" />
                
                </div>
              </div>
         <template v-if="type=='importazioni'">
              <div
                class="form-check form-switch form-switch-right form-switch-md mt-50 background-btn-grey-1"
              >
                <label for="FormSelectDefault" class="form-label text-muted"
                  >Carica file per importazione</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  v-model="showImportFileMod"
                />
              </div>
              <div class=" pl-20 pr-20 pt-20 pb-20" v-if="showImportFileMod">
                <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
                  <div class="col-md-12 col-lg-12">
                    <input class="form-control" id="multiFileListImport" type="file" :accept="fileAccept"  multiple="multiple" @change="previewFileImport" />

                  </div>
                </div>
              </div>
          </template>
          <div class="mt-50 pl-20 pr-20">
            
            <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
            <div class="col-md-12 col-lg-12">
                
                <div class="mb-3 form-icon">
                    
                    <input
                        type="text"
                        id="search"
                        v-model="search"
                        placeholder="Digita qui termine di ricerca ..."
                        class="form-control-search p-3 mt-20 mb-0.5 w-full border border-blue-300 rounded"
                        @input="getList(projectID)"
                    >                        
                              
                </div>
            </div>
        </div>

            <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
               
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Numero File
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <div class="table-card mb-1">
                <div class="row thRow  ">

                    <div class="col text-left">
                        File
                    </div>
                    <div class="col text-left">
                      Nome originale del file
                    </div>
                    <div class="col text-left">
                      Cartella
                    </div>

                    <div class="col text-left">
                      Tipo di file
                    </div>
                    <div class="col text-left">
                      Tipo documento
                    </div>
                    <div class="col text-left">
                      Dimensione (Kb)
                    </div>
                    <div class="col text-left">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of fileList" :key="index">

                    <div class="col text-left">
                        <template v-if="!fileList[index].resp || fileList[index].resp == ''">
                            <button type="button" class="btn btn-dark custom-toggle active" data-bs-toggle="button" title="Scarica" @click="getFilePath(item.path,index)">
                                <span class="icon-on"><i class="ri-download-line align-bottom"></i></span>
                            </button>
                        </template>
                        <template v-else>
                            <a :href="fileList[index].resp" target="blank">Apri</a>
                        </template>
                    </div>
                    <div class="col text-left">
                      {{ item.originalname }}
                    </div>
                    <div class="col text-left">
                        <template v-if="item.subdir_tag">
                            <strong>{{ item.subdir_tag}}</strong>
                        </template>
                        <template v-else>
                            <strong>/</strong>
                        </template>
                        
                    </div>
                    <div class="col text-left">
                      {{ item.mimetype }}
                    </div>
                    <div class="col text-left">
                      <template v-if="item.tag_document">
                        {{ item.tag_document }}
                      </template>
                    </div>
                    <div class="col text-left">
                      {{ formatKb(item.size) }}
                    </div>
                    <div class="col text-center ">
                      <ul class="list-inline hstack text-center gap-2 mb-0">

                          <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Rimuovi">
                           
                            <a class="text-danger d-inline-block remove-item-btn" href="javascript:void(0)" @click="deletedata(item._id, item.path)">
                              <i class="ri-delete-bin-5-fill fs-16"></i>
                            </a>
                          </li>
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover"
                            data-bs-placement="top" title="Modifica">   
                            <detailFile :id="item._id" :data="item" :projectID="projectID" :detailProject="{project_type:data.systemSubCategory,customer:data.customerDetails}" type="project" :getTag="getTag" :url="fileList[index].resp" @getParentProject="getParentProject"  />
                          </li>
                        </ul>
                    </div>
                </div>
          </div>
          <div class="d-flex tRow justify-content-end mt-3 mb-50" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
          </div> 
        </div>
      <!-- / Table -->
      </div>
    </div>
  </div>

  <div class="row mt-2" v-if="imgInList>0">
    <div class="col-lg-4 col-md-12 pb-10 pt-10 pr-10">
      <h3>
        <span class="ri-gallery-fill"></span>
        Immagini e galleria</h3>
      <a href="javascript:void(0)" @click="getChild()">
       
         (Apri)
      </a>
    </div>
    <div class="col-lg-8 col-md-12">
    </div>
  </div>
  
  <div class="row mt-10"  v-if="imgInList>0">
    <div class="col-lg-12 col-md-12 m-20">
      <gallery 
      :url="`${this.UrlServer}storage/gallery/by/project`"
      :projectID="this.projectID" 
      :data="this.data" 
      :bucket="this.bucket"
      :subdir_tag="this.subdir_tag"
      :galleryItems="imgInList"
      ref="passInChild"
      />
    </div>
  </div>


</div>
</template>